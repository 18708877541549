import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FaExclamationTriangle,
  FaCheckCircle,
  FaUpload,
  FaEye,
} from "react-icons/fa";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import StatusButton from "./StatusButton";

const TableResult = ({
  data,
  total,
  currentPage,
  handleEdit,
  handleGetFile,
  handleUpload,
  handleToggleStatus,
  handleOnPage,
  isLoading,
  showTable,
  handleUpdateValidation,
  handleOpenJustification,
}) => {

  const validateButtonStatus = (fee) => {
    const cantValidate = localStorage.getItem("profile_canValidate") !== "true";

    if (fee.updated_by !== null && fee.financ_status === null) {
      return {status: null, disabled: cantValidate};
    }
    else if (fee.updated_by === null && fee.financ_status === null) {
      return {status: 2, disabled: true};
    }
    else {
      return {status: fee.financ_status ? 1 : 0, disabled: cantValidate};
    }
  };

  const paginationCalulate = () => {
    const totalItems = total;
    const itemsPerPage = 10;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return totalPages;
  };

  const tableheaderbgc = useColorModeValue("gray.100", "#171923");

  if (showTable && isLoading) {
    return <Skeleton height={300} width={"100%"} />;
  }

  return (
    <>
      <Flex gap="4" mt="4" justify="center">
        <TableContainer mt="8" width={"100%"}>
          <Table variant="simple">
            <Thead bg={tableheaderbgc}>
              <Tr>
                <Th>Tipo</Th>
                <Th>Curso</Th>
                <Th>Tipo Pagamento</Th>
                <Th>Valor</Th>
                <Th>Parcela</Th>
                <Th>Vencimento</Th>
                <Th style={{ textAlign: "center" }}>Ações</Th>
                <Th style={{ textAlign: "center" }}>Validação Financeira</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((fee) => (
                <Tr key={fee.ID}>
                  <Td>{fee.TIPO_DEB || "N/A"}</Td>
                  <Td>{fee.CURSO || "N/A"}</Td>
                  <Td>{fee.TIPO_PAG || "N/A"}</Td>
                  <Td>{fee.VALOR || "N/A"}</Td>
                  <Td>{fee.PARC || "N/A"}</Td>
                  <Td>{fee.VENCTO || "N/A"}</Td>
                  <Td>
                    <Flex justify="center" gap="2">
                      <Button
                        size="sm"
                        onClick={() => handleEdit(fee)}
                        leftIcon={<EditIcon />}
                        colorScheme="yellow"
                        isDisabled={localStorage.getItem("profile_canEdit") !== "true"} // Bloqueia ou habilita com base no estado
                      >
                        Editar
                      </Button>
                      {fee.upload_file_id && (
                        <Button
                          width={"200px"}
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleGetFile(fee.upload_file_id)}
                          leftIcon={<FaEye />}
                        >
                          Visualizar Comprovante
                        </Button>
                      )}
                      {!fee.upload_file_id && (
                        <Button
                          width={"200px"}
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleUpload(fee)}
                          leftIcon={<FaUpload />}
                          isDisabled={localStorage.getItem("profile_canEdit") !== "true"} // Bloqueia ou habilita com base no estado
                        >
                          Importar Comprovante
                        </Button>
                      )}
                      <Button
                        width={"120px"}
                        size="sm"
                        onClick={() => handleToggleStatus(fee)}
                        isDisabled={localStorage.getItem("profile_canEdit") !== "true"} // Bloqueia ou habilita com base no estado
                        leftIcon={
                          fee.SYS_STATUS ? (
                            <FaExclamationTriangle />
                          ) : (
                            <FaCheckCircle />
                          )
                        }
                        colorScheme={fee.SYS_STATUS ? "red" : "green"}
                      >
                        {fee.SYS_STATUS ? "Pendente" : "Pago"}
                      </Button>
                    </Flex>
                  </Td>
                  <Td>
                    <StatusButton
                      fee={fee}
                      status={validateButtonStatus(fee)}
                      onUpdateValidation={handleUpdateValidation}
                      onOpenJustification={handleOpenJustification} // Passando a função
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>

      <Flex mt="4" alignItems="center" gap={3}>
        <ResponsivePagination
          current={currentPage}
          total={paginationCalulate()}
          onPageChange={handleOnPage}
          maxWidth={500}
        />
      </Flex>
    </>
  );
};

export default TableResult;
