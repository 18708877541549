import axios from "axios";

class Students {
  // Configuração do cabeçalho para requisições
  getDefaultHeader() {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("Token não está definido.");
    }

    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Configuração do cabeçalho para requisições
  getUploadHeader() {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("Token não está definido.");
    }

    return {
      headers: {
        "Content-Type": "multipart/form-data",
        timeout: 60000,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Busca aluno pelo RA
  async getByRA(ra, pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/students?RA=${ra}&pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar aluno pelo RA:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Atualiza mensalidade (SYS_STATUS, VALOR, VENCTO, UPLOAD_FILE_ID)
  async updateData(id, updatedData) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ELDORADO_API_URL}/students/${id}`,
        updatedData,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao atualizar SYS_STATUS:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca arquivo do bucket, através do middleware
  async getFile(fileId) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/documents?fileId=${fileId}`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar arquivo:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Envia arquivo para o bucket, através do middleware
  async uploadFile(file) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ELDORADO_API_URL}/upload`,
        file,
        this.getUploadHeader()
      );

      return response.data;
    } catch (error) {
      console.error(
        "Erro ao enviar arquivo:",
        error.response?.data || error.message
      );
      throw error;
    }
  }
}

const student = new Students();
export default student;
