import { ChakraProvider } from "@chakra-ui/react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./views/Login";
import Home from "./views/Home";
import theme from "styles/theme";
import ProtectedRoute from "./ProtectedRoute";

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/mensalidades" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}