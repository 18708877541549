import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";

const JustificationModal = ({ isOpen, onClose, onSave }) => {
  const [justification, setJustification] = useState("");

  const handleSave = () => {
    onSave(justification); // Salva a justificativa no estado
  };

  const handleClose = () => {
    onClose();
    setJustification(""); // Limpa o campo ao fechar modal
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Justificativa para Recusa</ModalHeader>
        <ModalBody>
          <Textarea
            placeholder="Descreva o motivo da recusa"
            value={justification}
            onChange={(e) => setJustification(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleSave}>
            Salvar
          </Button>
          <Button onClick={handleClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default JustificationModal;
