import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
  Spacer,
} from "@chakra-ui/react";

import NavBarHeader from "../Header";
import Footer from "../Footer";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Students from "../../services/Students";
import EditModal from "components/Modals/Edit";
import { formatToBackendDate } from "utils";
import UploadModal from "components/Modals/Upload";
import { allowedExtensions } from "components/Modals/Upload";
import { pathOr } from "ramda";
import TableResult from "components/Table";
import { toastError, toastInfo, toastSuccess } from "./contants";
import JustificationModal from "components/Modals/Justification/JustificationModal";
import DeleteDocumentModal from "components/Modals/DeleteDocument";

const schema = yup.object().shape({
  ra: yup
    .number("Insira um RA válido")
    .typeError("Insira um RA válido")
    .required("Esse campo é obrigatório"),
});

export default function Home() {
  const toast = useToast();
  const formRef = useRef(null);

  const { register, handleSubmit, watch } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      ra: "",
    },
  });

  const ra = watch("ra");

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ra: "" },
  });

  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [monthlyFee, setMonthlyFee] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [monthlyFees, setMonthlyFees] = useState({ total: 0, data: [] });
  const [studentName, setStudentName] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const [selectedFee, setSelectedFee] = useState(null); // Item selecionado
  const [selectedValidationStatus, setSelectedValidationStatus] = useState(null); // Status da validação financeira

  // Estado para modal de justificativa
  const [isJustificationModalOpen, setIsJustificationModalOpen] = useState(false); // Controle do modal

  // Abre o modal de justificativa
  const handleOpenJustificationModal = (fee) => {
    setSelectedFee(fee);
    (setIsJustificationModalOpen)(true);
  };

  // Estado para modal de exclusão de documento
  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] = useState(false); // Controle do modal
  const [deleteDocumentModalSource, setDeleteDocumentModalSource] = useState(''); // Controle do modal

  // Abre o modal de exclusão de documento
  const handleOpenDeleteDocumentModal = (source, fee, validation) => {
    setSelectedFee(fee);
    setSelectedValidationStatus(validation);
    setDeleteDocumentModalSource(source);
    setIsDeleteDocumentModalOpen(true);
  };

  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);
        const studentData = await Students.getByRA(ra, currentPage - 1);
        setMonthlyFees(studentData);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (monthlyFees.total > 0) {
      init();
    }
  }, [currentPage, ra, monthlyFees.total]);

  const handleEdit = (fee) => {
    setMonthlyFee(fee);
    setShowEditModal(true);
  };

  const onSubmit = async (data) => {
    try {
      const studentData = await Students.getByRA(ra);

      if (!studentData || studentData.length === 0) {
        setShowTable(false);
        setStudentName(null);
        toast(toastInfo.notFound);
      } else {
        setMonthlyFees(studentData);
        setStudentName(studentData?.data[0]?.NOME || "Desconhecido");
        setShowTable(true);
        toast(toastSuccess.found);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do aluno:", error);
      toast(toastError.notFound);
    }
  };

  const handleUpload = (fee) => {
    setMonthlyFee(fee);
    setShowUploadModal(true);
  };

  const handleOnchangeEdit = (field, value) => {
    const formatValue = field === "VENCTO" ? formatToBackendDate(value) : value;

    setMonthlyFee({
      ...monthlyFee,
      [field]: formatValue,
    });
  };

  const handleGetFile = async (fileId) => {
    try {
      const fileUrl = await Students.getFile(fileId);
      window.open(fileUrl.fileUrl, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmEdit = async () => {
    try {
      let updatedFee = {
        VALOR: monthlyFee.VALOR,
        VENCTO: monthlyFee.VENCTO
      }

      updatedFee = await Students.updateData(monthlyFee.ID, updatedFee);

      setMonthlyFees({
        total: monthlyFees.total,
        data: pathOr([], ["data"], monthlyFees).map((item) =>
          item.ID === updatedFee.ID ? updatedFee : item
        ),
      });

      toast(toastSuccess.monthlyFeeUpdated);
    } catch (error) {
      console.error(
        "Erro ao atualizar mensalidade:",
        error.response?.data || error.message
      );
      toast(toastError.updateMonthlyFeeError(error));
    } finally {
      setShowEditModal(false);
      setMonthlyFee({});
    }
  };

  const handleToggleStatus = async (fee) => {
    if (!fee.SYS_STATUS && fee.upload_file_id) {
      handleOpenDeleteDocumentModal('toggleStatus', fee);
    } else {
      await toogleStatus(fee);
    }
  };

  // Alera status da mensalidade
  const toogleStatus = async (fee) => {
    try {
      fee.SYS_STATUS = !fee.SYS_STATUS;

      let updatedFee = {
        SYS_STATUS: fee.SYS_STATUS,
      }

      updatedFee = await Students.updateData(fee.ID, updatedFee);

      if (updatedFee) {
        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === updatedFee.ID ? updatedFee : item
          ),
        });
      }
      toast(toastSuccess.updateFeeStatus(updatedFee));
    } catch (error) {
      toast(toastError.updateMonthlyFee(error));
      console.error(
        "Erro ao atualizar SYS_STATUS:",
        error.response?.data || error.message
      );
    }
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = `.${selectedFile.name.split(".").pop()}`;
      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        toast(toastError.fileError);
        setFile(null);
        return;
      }
      setFile(selectedFile);
    }
  };

  const handleFileUpload = async (fee) => {
    if (!file) {
      toast(toastError.emptyFile);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const responseUpload = await Students.uploadFile(formData);
      if (responseUpload) {
        fee.upload_file_id = responseUpload.fileId;

        const feeData = { upload_file_id: responseUpload.fileId };
        await Students.updateData(monthlyFee.ID, feeData);

        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === fee.ID ? fee : item
          ),
        });

        toast(toastSuccess.upload(file.name));
      }
      else {
        toast(toastError.notFoundMonthlyFee);
        return;
      }
    } catch (error) {
      toast(toastError.upload(error));
    } finally {
      setMonthlyFee({});
      setFile(null);
      setShowUploadModal(false);
    }
  };

  const handleUpdateValidation = async (fee, newStatus) => {
    if (newStatus === false && fee.upload_file_id) {
      handleOpenDeleteDocumentModal('updateValidation', fee, newStatus);
    } else {
      await updateValidation(fee, newStatus);
    }
  };

  // Salva a validação financeira
  const updateValidation = async (fee, newStatus) => {
    try {
      fee.financ_status = newStatus;

      let updatedFee = {
        financ_status: fee.financ_status,
        financ_obs: fee.financ_obs
      }
      updatedFee = await Students.updateData(fee.ID, updatedFee);

      if (updatedFee) {
        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === updatedFee.ID ? updatedFee : item
          ),
        });
      }
      
      setIsJustificationModalOpen(false);
      toast(toastSuccess.updateFeeValidation(updatedFee));
    } catch (error) {
      toast(toastError.updateValidation(error));
      console.error(
        "Erro ao atualizar financ_status:",
        error.response?.data || error.message
      );
    }
  }

  // Salva a justificativa da reprova da validação financeira
  const handleSaveJustification = async (justification) => {
    selectedFee.financ_obs = justification;
    handleUpdateValidation(selectedFee, false);
  };

  // Permite excluir o documento
  const handleDeleteDocument = async () => {
    if (deleteDocumentModalSource === 'toggleStatus') {
      await toogleStatus(selectedFee);
    }

    if (deleteDocumentModalSource === 'updateValidation') {
      await updateValidation(selectedFee, selectedValidationStatus);
    }

    setIsDeleteDocumentModalOpen(false);
  };

  return (
    <Flex direction="column" minHeight="100vh">
      <NavBarHeader />
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap="24px" padding={"40px"}>
          <Flex align="center" gap="24px" mb="4" justify="center">
            <Text fontWeight="bold" fontSize="24px">
              Mensalidades
            </Text>
          </Flex>
          <Flex justify="center">
            <FormControl isInvalid={errors.ra}>
              <FormLabel fontWeight="semibold">RA do aluno</FormLabel>
              <Input
                placeholder="Digite o RA do aluno"
                borderColor="gray.400"
                {...register("ra")}
              />
              <FormErrorMessage>{errors?.ra?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex gap="4" mt="4" justify="center">
            <Button colorScheme="red" type="submit">
              Consultar
            </Button>
          </Flex>
          {studentName && ra && (
            <Flex flexWrap={"wrap"} gap="4" mt="4" justify="left">
              <Text flexBasis={"100%"} fontWeight="bold" fontSize="18px">
                Aluno(a): {studentName}
              </Text>
              <Text flexBasis={"100%"} fontWeight="bold" fontSize="18px">
                RA: {ra}
              </Text>
              <Text flexBasis={"100%"} fontWeight="normal" fontSize="12px">
                Total de registros encontrados:{" "}
                {pathOr(0, ["total"], monthlyFees)}
              </Text>
            </Flex>
          )}
          {showTable && (
            <TableResult
              isLoading={isLoading}
              showTable={showTable}
              data={monthlyFees.data}
              total={monthlyFees.total}
              handleEdit={handleEdit}
              handleGetFile={handleGetFile}
              handleUpload={handleUpload}
              handleToggleStatus={handleToggleStatus}
              handleOnPage={setCurrentPage}
              currentPage={currentPage}
              handleUpdateValidation={handleUpdateValidation}
              handleOpenJustification={handleOpenJustificationModal}
            />
          )}
        </Flex>
      </form>
      <Spacer />
      <Footer />

      <EditModal
        showModal={showEditModal}
        onChange={handleOnchangeEdit}
        close={() => setShowEditModal(false)}
        save={handleConfirmEdit}
        data={monthlyFee}
        currentPage={currentPage}
      />

      <UploadModal
        showModal={showUploadModal}
        close={() => {
          setShowUploadModal(false);
          setFile(null);
          setMonthlyFee({});
        }}
        onChange={handleFileChange}
        save={() => handleFileUpload(monthlyFee)}
        data={file}
      />

      <JustificationModal
        isOpen={isJustificationModalOpen}
        onClose={() => setIsJustificationModalOpen(false)} // Fecha o modal sem salvar
        onSave={handleSaveJustification} // Salva a justificativa e atualiza
      />

      <DeleteDocumentModal
        isOpen={isDeleteDocumentModalOpen}
        onClose={() => setIsDeleteDocumentModalOpen(false)}
        onConfirm={handleDeleteDocument}
        source={deleteDocumentModalSource}
      />
    </Flex>
  );
}
