import React from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = (props) => {
  const isAuthenticated = localStorage.getItem('token');
 
  if (isAuthenticated) {
    return <>{props.children}</>
  }

  return <Navigate to="/" />
}

export default ProtectedRoute