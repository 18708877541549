import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { FaSignOutAlt } from 'react-icons/fa';
import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Stack,
  useColorMode,
  useColorModeValue,
  Text
} from "@chakra-ui/react";

export default function NavBarHeader() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [email] = useState(localStorage.getItem("email"));
  const [name] = useState(localStorage.getItem("name"));
  const [profile] = useState(localStorage.getItem("profile"));

  function logOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("profile");
    localStorage.removeItem("profile_canEdit");
    localStorage.removeItem("profile_canValidate");
    window.location.href = "/";
  }

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={12} py={2}>
        <Flex alignItems={"center"} justifyContent={"left"} flexWrap={"wrap"} >
          <Flex>
            <Link href="/">
              <Image
                maxW="100%"
                w={320}
                src={
                  colorMode === "light"
                    ? "/assets/logo-dark.png"
                    : "/assets/logo-light.png"
                }
              />
            </Link>
          </Flex>

          <Flex justifyContent={{ base: "center", lg: "right" }} alignItems={"center"} flexGrow={1} flexWrap={"wrap"}>
            <Flex justifyContent={{ base: "center", lg: "right" }} alignItems={"center"} flexGrow={1}>
              <Text spacing={7}>
                {name || email} - {profile}
              </Text>
            </Flex>
            <Flex paddingLeft={{ base: 0, lg: 5 }} alignItems={"center"} justifyContent={"right"}>
              <Stack direction={"row"} spacing={7} style={{ padding: 5 }}>
                <Button onClick={toggleColorMode}>
                  {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                </Button>
              </Stack>
              <Stack direction={"row"} spacing={7}>
                <Button onClick={logOut}>
                  <FaSignOutAlt />
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
