import axios from "axios";

class Users {
  // Configuração do cabeçalho para requisições 
  getDefaultHeader() {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token não está definido.");
    } 

    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Busca usuário pelo e-mail
  async getByEmail(email) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/user/${email}`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar usuário pelo e-mail:",
        error.response?.data || error.message
      );
      throw error;
    }
  }
}

const user = new Users();
export default user;
