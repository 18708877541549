import { Box, useColorModeValue } from "@chakra-ui/react";
import RightsReservedContainer from "./RightsReservedContainer";
import SocialBarContainer from "./SocialBarContainer";

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <SocialBarContainer />
      <RightsReservedContainer />
    </Box>
  );
}
