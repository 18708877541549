import { Container, Image, Stack, useColorMode } from "@chakra-ui/react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import SocialButton from "../SocialButton";

export default function SocialBarContainer() {
  const { colorMode } = useColorMode();

  return (
    <Container
      as={Stack}
      maxW={"8xl"}
      pt={6}
      direction={{ base: "column", md: "row" }}
      spacing={4}
      justify={{ base: "center", md: "space-between" }}
      align={{ base: "center", md: "center" }}
    >
      <Image
        width={"320px"}
        src={
          colorMode === "light"
            ? "/assets/logo-dark.png"
            : "/assets/logo-light.png"
        }
      />

      <Stack direction={"row"} spacing={4}>
        <SocialButton label={"Facebook"} href={"https://www.facebook.com/fmu/"}>
          <FaFacebookF />
        </SocialButton>
        <SocialButton
          label={"Instagram"}
          href={"https://www.instagram.com/fmu_oficial/"}
        >
          <FaInstagram />
        </SocialButton>
        <SocialButton label={"Twitter"} href={"https://twitter.com/fmuoficial"}>
          <FaTwitter />
        </SocialButton>
        <SocialButton
          label={"YouTube"}
          href={"https://www.youtube.com/user/fmuoficial"}
        >
          <FaYoutube />
        </SocialButton>
        <SocialButton
          label={"Linkedin"}
          href={
            "https://www.linkedin.com/school/centro-universitario-fmu-fiam-faam/"
          }
        >
          <FaLinkedin />
        </SocialButton>
        <SocialButton
          label={"TikTok"}
          href={"https://vm.tiktok.com/ZM81C3qLs/"}
        >
          <FaTiktok />
        </SocialButton>
        <SocialButton
          label={"Whatsapp"}
          href={
            "https://api.whatsapp.com/send?phone=551131323000&text=Acessei%20o%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
          }
        >
          <FaWhatsapp />
        </SocialButton>
      </Stack>
    </Container>
  );
}
