import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    red: {
      lighter: "#F98198",
      light: "#F67766",
      main: "#FF0030",
      dark: "#BF072A",
    },
  },
});

export default theme;
