import { Box, Container, Text, useColorModeValue } from "@chakra-ui/react";

export default function RightsReservedContainer() {
  return (
    <Container maxW={"6xl"} pt={4}>
      <Box
        py={6}
        borderTop="1px solid"
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Text align="center">
          © 2024 – Centro Universitário FMU. Todos os direitos reservados.
        </Text>
      </Box>
    </Container>
  );
}
